<template>
  <div class="stellantis h-screen flex flex-col">
    <div class="container mx-auto w-full">
      <img :src="persos[id].logo" class="w-36 md:w-64 mx-auto my-8" alt="">
      <div class="flex flex-wrap w-full justify-center mb-28 md:mb-60">
        <div class="w-10/12 text-center">
          <font class="text-stellantis-500 font-extrabold text-2xl md:text-4xl block">Merci. Nous avons bien enregistré votre demande.</font>
          <p class="text-xl mt-8 mb-6">Un conseiller Stellantis &You Lille vous contactera dans les meilleurs délais.</p>
          <a :href="persos[id].cta[0]" class="bg-fiat-500 px-8 py-4 rounded text-white hover:bg-fiat-530 inline-block transition text-xl md:text-2xl" v-html="persos[id].cta[1]"></a>
        </div>
      </div>
    </div>
    <Footer marque="stellantis">
      <div v-html="persos[id].footer"></div>
    </Footer>
    <div class="bg-stellantis-900" style="height: inherit;">&nbsp;</div>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
export default{
  components: {
    Footer
  },
  data(){
    return{
      id: 'defaut',
      persos: {
        defaut: {
          logo: 'img/logo/STELLANTIS-and-you-logo-blue.png',
          cta: ['https://www.fca-motorvillage.fr/distributeur/fiat', 'Découvrir Stellantis &You - Lille'],
          footer: `
            <img src="img/logo/STELLANTIS&YOU_logo_white_350x60.png" alt="" class="w-52 mx-auto my-12">
          `
        },
        '1': {
          logo: 'img/logo/STELLANTIS-and-you-logo-blue.png',
          cta: ['https://www.fca-motorvillage.fr/distributeur/fiat', 'Découvrir Stellantis &You - Lille'],
          footer: `
            <img src="img/logo/STELLANTIS&YOU_logo_white_350x60.png" alt="" class="w-52 mx-auto my-12">
          `
        }

      }
    }
  },
  methods: {
    param() {
      let a = localStorage.getItem('confirm')
      a != undefined ? this.id = a : this.id = 'defaut'
    }
  },
  mounted(){
    this.param()
  }
}
</script>